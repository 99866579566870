import React, { Component } from 'react';
// import scrollToComponent from 'react-scroll-to-component';
import * as api from 'utils/api';
import Loading from 'shared/loading';
import Notification from 'shared/notification';
import AppDetails from 'components/apps/app_details';
import AppMemberList from 'components/apps/app_member_list';
import AppEventsTable from 'components/apps/app_events_table';
import AppSdkList from 'components/apps/app_sdk_list';
import AppReleaseList from 'components/apps/app_release_list';
import AppSettings from 'components/apps/app_settings';
import { adminBaseUrl, atriumBaseUrl } from 'resources';

class AppShow extends Component {
  constructor(params) {
    super(params);
    this.state = {
      data: { organization: {} },
      errors: [],
      loading: true,
      ebrRequested: false,
    };

    this.fetch = this.fetch.bind(this);
    this.generateEBR = this.generateEBR.bind(this);
    this.dismissErrors = this.dismissErrors.bind(this);
    this.dismissInfo = this.dismissInfo.bind(this);
    this.favorite = this.favorite.bind(this);
    this.scrollToSettings = this.scrollToSettings.bind(this);
  }

  componentDidMount() {
    document.title = 'App Show';
    this.fetch(this.props.match.params.id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.match.params.id && !this.state.loading) {
      this.fetch(nextProps.match.params.id);
    }
  }

  async fetch(id) {
    try {
      const response = await api.get(`${adminBaseUrl}/apps/${id}`);
      const data = await response.json();
      if (data && data.error) {
        this.setState({
          errors: [`Failed to fetch App: ${data.error}`, ...this.state.errors],
          loading: false,
        });
      } else if (data) {
        this.setState({
          data,
          loading: false,
        });
      }
    } catch (error) {
      this.setState({
        errors: [`Failed to fetch App: ${error}`, ...this.state.errors],
        loading: false,
      });
    }
  }

  async generateEBR() {
    try {
      const body = {
        target_class: 'App',
        target_id: this.state.data.id,
        reporter_class: 'CsExecBusinessReviewReporter',
        fmt: 'txt',
      };
      const response = await api.post(`${atriumBaseUrl}/apps/${this.state.data.id}/report_jobs`, body);
      const data = await response.json();
      if (data && data.error) {
        this.setState({
          errors: [`Failed to generate EBR: ${data.error}`, ...this.state.errors],
          ebrRequested: false,
        });
      } else if (data) {
        this.setState({ ebrRequested: true });
      }
    } catch (error) {
      this.setState({
        errors: [`Failed to generate EBR: ${error}`, ...this.state.errors],
        ebrRequested: false,
      });
    }
  }

  dismissErrors(event) {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      errors: [],
    });
  }

  dismissInfo(event) {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      ebrRequested: false,
    });
  }

  favorite(id) {
    return (_event) => {
      api.post(`${adminBaseUrl}/favorites?id=${id}&type=apps`);
    };
  }

  scrollToSettings() {
    document.getElementById('appSettingsAnchor').scrollIntoView();
  }

  render() {
    return (
      <div className="container">
        <div className="columns">
          <div className="column">
            <nav className="breadcrumb" aria-label="breadcrumbs">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/apps">Apps List</a>
                </li>
                <li className="is-active">
                  <a href={`/apps/${this.props.match.params.id}`} aria-current="page">{this.props.match.params.id}</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="column">
            <nav className="breadcrumb has-no-separator is-right">
              <ul>
                <li>
                  <a href={`/apps/${this.props.match.params.id}/interactions`}>
                    <button className="button is-small" type="button">App Interactions</button>
                  </a>
                </li>
                <li><button className="button is-small" type="button" onClick={this.scrollToSettings}>Edit Settings</button></li>
                <li><button className="button is-small" type="button" onClick={this.favorite(this.props.match.params.id)}>Favorite</button></li>
                <li><button className="button is-small" type="button" onClick={this.generateEBR}>Generate EBR</button></li>
              </ul>
            </nav>
          </div>
        </div>
        {
          this.state.ebrRequested || this.state.errors.length ?
            <div className="columns">
              <div className="column">
                {this.state.errors.length ? <Notification type="danger" content={this.state.errors} dismiss={this.dismissErrors} /> : null}
                {this.state.ebrRequested ? <Notification
                  type="link"
                  content={[(<span key="ebr">EBR Report generated, please check the <a href={`${process.env.HOST_URL}/apps/4eec2417687bd9000100000a/dashboard`} target="_blank" rel="noopener noreferrer">Apptentive Dashboard</a> for your link.</span>)]}
                  dismiss={this.dismissInfo}
                /> : null}
              </div>
            </div> : null
        }
        {
          this.state.loading ?
            <Loading text="Loading..." /> :
            <div>
              <AppDetails key={`${this.props.match.params.id}-AppDetails`} app={this.state.data} />
              <AppEventsTable key={`${this.props.match.params.id}-AppEventsTable`} app={this.state.data} />
              <AppMemberList key={`${this.props.match.params.id}-AppMemberList`} app={this.state.data} />
              <AppSdkList key={`${this.props.match.params.id}-AppSdkList`} app={this.state.data} />
              <AppReleaseList key={`${this.props.match.params.id}-AppReleaseList`} app={this.state.data} />
              <div id="appSettingsAnchor" />
              <AppSettings key={`${this.props.match.params.id}-AppSettings`} app={this.state.data} />
            </div>
        }
      </div>
    );
  }
}

export default AppShow;
